.contact-container {
  min-height: 100vh;
  padding: 20px;
  background: linear-gradient(to bottom, #c8b37d, #343232);
  font-family: Arial, sans-serif;
  position: relative;
  margin-top: 0;
  padding-top: 100px;
}

.contact-wrapper {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.contact-header {
  text-align: center;
  margin-bottom: 40px;
  position: relative;
}

.contact-title {
  font-size: 3.5rem;
  color: rgb(255, 225, 107);
  margin-bottom: 20px;
  font-weight: 600;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  background-clip: padding-box; 
  display: inline-block;
}

.contact-card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  position: relative;
  z-index: 2;
  width: 100%;
}

.contact-card:hover {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  z-index: 2;
}

.form-group {
  margin-bottom: 16px;
  position: relative;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
  background-color: #f9fafb;
  position: relative;
  z-index: 2;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #c8b37d;
  box-shadow: 0 0 0 2px rgba(200, 179, 125, 0.1);
  z-index: 3;
}

.form-group textarea {
  min-height: 120px;
  resize: vertical;
}

.submit-button {
  background-color: #E4A11B;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  z-index: 2;
}

.submit-button:hover:not(:disabled) {
  background-color: #b49f6d;
  transform: translateY(-1px);
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.alert {
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  z-index: 2;
}

.alert.success {
  background-color: #f0fdf4;
  border: 1px solid #86efac;
  color: #166534;
}

.alert.error {
  background-color: #fef2f2;
  border: 1px solid #fecaca;
  color: #991b1b;
}

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .contact-container {
    padding-top: 120px;
  }
}
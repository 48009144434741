.carousel .control-dots .dot {
    background-color: #333;
  }
  .carousel .control-dots .dot.selected {
    background-color: #dfdf09;
  }

  .carousel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 1px solid #ccc;
    padding: 1em;
    max-width: 800px;
    width: 100%;
    margin-top: 2em;
  }
  
  h2 {
    font-size: 2em;
    margin-bottom: 0.5em;
    margin-top: 7em;
  }
  
  h5 {
    font-size: 1em;
    color: #666;
    margin-top: 0.5em;
    margin-bottom: 3em;
  }
  
  @media (max-width: 600px) {
    h2 {
      font-size: 2.5em;
    }
  
    h5 {
      font-size: 1em;
    }
  
    .carousel-container {
      margin-top: 1em;
    }
  }
  
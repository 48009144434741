.about-page {
  width: 100%;
  padding-top: 85px;
  min-height: 100vh;
  background: linear-gradient(to bottom, #c8b37d, #343232);
  color: #fff;
}

/* Hero Section */
.hero-section-about {
  position: relative;
  height: 500px;
  background-image: url('https://images.unsplash.com/photo-1698954634383-eba274a1b1c7');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.hero-section-about h1 {
  color: rgb(255, 225, 107);
  font-size: 3.5rem;
  font-weight: 700;
  position: relative;
  z-index: 1;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid currentColor;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
}

/* Container */
.about-container {
  max-width: 1200px;
  padding: 0 1.5rem;
  padding-bottom: 1.5rem;
  margin: 0 auto;
  margin-top: 10px;
}

/* Mission Vision Section */
.mission-vision-section {
  margin: 0 auto 3rem;
}

.mission-vision-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.info-card-about {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.info-card-about:hover {
  transform: translateY(-5px);
}

.info-card-about h3 {
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e74c3c;
}

.info-card-about p {
  line-height: 1.6;
  color: #505050;
}

/* Main Content Section */
.main-content {
  background-color: rgba(255, 255, 255, 0.95);
  padding: 3rem;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.content-grid {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.bio-content {
  max-width: 800px;
}

.bio-section {
  line-height: 1.8;
  color: #2c3e50;
}

.bio-section p {
  padding: 10px;
  margin-bottom: 1rem;
  padding-left: 0;
}

.bio-section h2 {
  font-size: xx-large;
  padding-top: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

/* Values Section */
.values-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin: 2rem 0;
}

.value-card {
  background-color: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  flex: 1 1 calc(30% - 1rem);
  min-width: 280px;
  transition: transform 0.3s ease;
}

.value-card:hover {
  transform: translateY(-5px);
}

.value-title {
  font-size: 1.8rem;
  color: #e74c3c;
  margin-bottom: 1rem;
}

.value-description {
  font-size: 1rem;
  line-height: 1.6;
  color: #505050;
}

/* Social Section */
.social-links {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.social-link {
  transition: transform 0.2s ease;
  position: relative;
  z-index: 10;
}

.social-link:hover {
  transform: translateY(-4px);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hero-section-about {
    height: 600px;
    margin-top: 0;
  }

  .hero-section-about h1 {
    font-size: 2.5rem;
  }

  .mission-vision-section {
    margin-top: 1rem;
  }

  .mission-vision-grid {
    grid-template-columns: 1fr;
  }

  .main-content {
    padding: 1.5rem;
  }

  .values-container {
    flex-direction: column;
  }

  .value-card {
    min-width: 100%;
  }
}

@media (max-width: 480px) {
  .hero-section-about {
    height: 650px;
  }

  .hero-section-about h1 {
    font-size: 2rem;
  }

  .about-container {
    padding: 0 1rem;
  }

  .main-content {
    padding: 1rem;
  }

  .social-links {
    gap: 1rem;
  }
}
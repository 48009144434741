/* Messages Page Styles */
.messages-page {
  width: 100%;
  padding-top: 65px;
  background: #f8fafc;
  min-height: 100vh;
}

/* Hero Section */
.hero-section-message {
  position: relative;
  height: 500px;
  background-image: url('https://images.unsplash.com/photo-1505855602532-fa8341a13055?q=80&w=1780');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
  text-align: center;
  padding: 0 1rem;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.hero-section-message h1 {
  color: rgb(255, 225, 107);
  font-size: 3.5rem;
  font-weight: 700;
  position: relative;
  z-index: 1;
  text-align: center;
  font-family: Georgia, 'Times New Roman', Times, serif;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid currentColor;
}

.hero-subtitle {
  color: #ffffff;
  font-size: 1.2rem;
  position: relative;
  z-index: 1;
  max-width: 800px;
  line-height: 1.6;
}

/* Messages Container */
.messages-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
}

/* Messages Grid */
.messages-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

/* Message Card */
.message-card {
  background: rgb(246, 225, 177);
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.message-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.card-content {
  padding: 1.5rem;
}

.verse-reference {
  display: inline-block;
  color: #2563eb;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.message-title {
  color: #1e293b;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  line-height: 1.4;
}

.verse-text {
  color: #64748b;
  font-style: italic;
  margin-bottom: 1rem;
}

.message-text {
  color: #4a5568;
  line-height: 1.6;
}

/* Videos Section */
.videos-section {
  margin-bottom: 3rem;
}

.videos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.video-card {
  background: rgb(247, 231, 172);
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.video-container {
  position: relative;
  padding-top: 56.25%;
}

.video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.video-title {
  padding: 1rem;
  color: #1e293b;
  font-weight: 600;
}

/* Reflections Section */
.reflections-section {
  margin-bottom: 3rem;
}

.reflection-card {
  background: rgb(241, 218, 159);
  border-radius: 0.75rem;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Subscribe Section */
.subscribe-section {
  margin-bottom: 3rem;
}

.subscribe-form {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.subscribe-input {
  flex: 1;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 1rem;
}

.subscribe-button {
  background: #E4A11B;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.subscribe-button:hover {
  background: #1d4ed8;
}

/* Section Titles */
.section-title {
  color: #1e293b;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e2e8f0;
}

/* Footer */
.messages-footer {
  text-align: center;
  padding: 2rem 0;
  color: #64748b;
  border-top: 1px solid #e2e8f0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hero-section-message {
    height: 300px;
  }

  .hero-section-message h1 {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1rem;
  }

  .messages-grid {
    grid-template-columns: 1fr;
  }

  .subscribe-form {
    flex-direction: column;
  }

  .subscribe-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .hero-section-message {
    height: 250px;
  }

  .hero-section-message h1 {
    font-size: 2rem;
  }

  .messages-container {
    padding: 0 1rem;
  }
}
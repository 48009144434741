.App {
  font-family: Arial, sans-serif;
  color: black;
  margin: 0; /* Remove any default margins */
  padding: 0; /* Remove any default padding */
}
/* General media query for smaller screens */
/* @media (max-width: 768px) {
  .App {
    padding: 0.5rem;
  }
} */

.app-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Allow full height */
}



@tailwind base;
@tailwind components;
@tailwind utilities;
/* Remove any margin-bottom from the last child in content
.content-wrapper > div:last-child {
  margin-bottom: 0;
} */

/* Ensure footer stays at the bottom */
/* .mdb-footer {
  flex-shrink: 0;
  position: relative;
  width: 100%;
  margin-top: auto;
} */
.navbar {
  padding: 0rem 0;
  z-index: 10;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: transparent !important;
  position: absolute; /* Stay above the background image */
  transition: background-color 0.3s ease;
  top: 0;
  left: 0;
  width: 100%;
}
.navbar {
  --bs-navbar-padding-x: 0 !important;
  --bs-navbar-padding-y: 0 !important;
}

.navbar > .container {
  max-width: 1400px; /* Match content wrapper width */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem; /* Add side padding */
  background-color: #c79236;
}
/* Menu items */
.navbar-nav .nav-link {
  color: #ffffff !important;
  padding: 0.5rem 1rem !important;
  font-weight: 700;
  font-size: 14px;
  position: relative;
  transition: all 0.3s ease;
  font-family: 'Poppins', sans-serif;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
}
.navbar-nav .nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;  /* Adjust this value to control border length */
  height: 1px;
  background-color: #ffe16b;
  z-index: 1;
}
/* Active/Selected state styling */ 
.navbar-nav .nav-link.active {
  border: 2px solid #000000;
  padding: 0.5rem 1.5rem !important;
}
.navbar-nav .nav-link.active::after {
  display: none;
}
/* Hover effect - optional, adds border on hover */
.navbar-nav .nav-link:hover:not(.active) {
  opacity: 0.8;
  border: 2px solid rgb(255, 255, 255);
}

/* Full-screen menu styles */
.full-screen-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(152, 130, 19, 0.95);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.full-screen-menu .close-button {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 30px;
  color: #e0cf0b;
  background: none;
  border: none;
  cursor: pointer;
}

.menu-list {
  list-style: none;
  padding: 0;
  text-align: center;
}

.menu-item {
  margin: 1rem 0;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.3s forwards;
}

.menu-item .nav-link {
  font-size: 24px;
  color: #f0cf0b;
  text-decoration: none;
}

.menu-item .nav-link.active {
  border: 2px solid #131111;
  padding: 0.5rem 1.5rem;
}

/* Desktop Navigation */
@media (min-width: 992px) {
  .navbar-brand {
    padding: 0;
    margin-right: 2rem;
  }

  .navbar > .container {
    max-width: 2600px;
    padding: 0 5rem; /* Add side padding */
  }

  .navbar-nav {
    display: flex !important;
    background-color: transparent !important;
    padding: 0 !important;
    margin: 0 !important;/*margin-left: auto;*/
  }

  /* Spacing between nav items */
  .navbar-nav .nav-item {
    margin: 0 0.1rem;
  }

  /* Custom styling for desktop hamburger */
  .navbar-toggler {
    display: block !important;
    margin-left: 1rem;
    border: none;
    padding: 0.5rem;
    background-color: transparent;
    border-radius: 0;
    z-index: 1001;
  }

  /* Adjust full screen menu for desktop */
  .full-screen-menu {
    background-color: rgba(152, 130, 19, 0.95);
  }

  /* Larger spacing for social links in desktop */
  .full-screen-menu .menu-item {
    margin: 1.5rem 0;
  }

  .full-screen-menu .nav-link {
    font-size: 28px;
  }
}

/* Mobile Navigation */
@media (max-width: 991.98px) {
  .navbar-nav {
    display: none;
  }
  .navbar > .container {
    padding: 0 1.5rem;
  }
  .navbar-brand {
    position: relative;
    z-index: 100;
    margin-right: auto;
  }

  .menu-item {
    margin: 0.75rem 0;
  }

  .menu-item .nav-link {
    font-size: 20px;
  }
}

/* Common hamburger styles */
.navbar-toggler {
  position: relative;
  z-index: 1001;
  border: none;
  padding: 0.5rem;
  background-color: transparent;
  border-radius: 0;
}

.navbar-toggler-icon {
  background-image: none !important;
  position: relative;
  width: 24px;
  height: 2px;
  background-color: #ffd700 !important;
  transition: all 0.3s ease-in-out;
}

.navbar-toggler-icon::before,
.navbar-toggler-icon::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: #ffd700;
  left: 0;
  transition: all 0.3s ease-in-out;
}

.navbar-toggler-icon::before {
  top: -8px;
}

.navbar-toggler-icon::after {
  bottom: -8px;
}

/* Hamburger animation */
.navbar-toggler.is-active .navbar-toggler-icon {
  background-color: transparent !important;
}

.navbar-toggler.is-active .navbar-toggler-icon::before {
  transform: rotate(45deg);
  top: 0;
}

.navbar-toggler.is-active .navbar-toggler-icon::after {
  transform: rotate(-45deg);
  bottom: 0;
}

/* Animation keyframes */
@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.navbar-nav .nav-link.highlight {
  font-weight: 600;
}

.white-line {
  border: 0;
  height: 1px;
  width: 100px;
  background-color: rgba(255, 255, 255, 0.735);
  margin: 2rem auto;
}

.navbar-brand {
  display: flex;
  align-items: center;
  padding: 1px 0; 
}

.navbar-brand img {
  height: 65px;
  width: auto;
  /* object-fit: contain; */
}

/* Logo size for desktop */
@media (min-width: 992px) {
  .navbar-brand img {
    height: 65px;
    padding: auto;

    /* width: auto; */
    /* object-fit: contain;  */
  }
}
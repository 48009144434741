/* Main container styling */
.form-container {
  min-height: 100vh;
  position: relative;
  background: linear-gradient(to bottom, #c8b37d, #343232);
  color: #fff;
  overflow-x: hidden; /* Prevent horizontal scroll */
  width: 100%;
  margin: 0;
  padding: 0;
}

/* Background image container */
.bg-img-container {
  position: relative;
  width: 100%;
  height: 60vh;
  max-height: 700px;
  overflow: hidden;
}

/* Background image styling */
.bg-img-join {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.6);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

/* Form wrapper */
.form-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}

/* Form header styles */
.form-header {
  text-align: center;
  padding-top: 100px;
  margin-bottom: 30px;
}

.form-header h1 {
  color: rgb(255, 225, 107);
  font-size: clamp(2rem, 5vw, 3.5rem); /* Responsive font size */
  font-weight: 700;
  font-family: Georgia, 'Times New Roman', Times, serif;
  margin: 0;
  padding: 0;
  line-height: 1.2;
}

.form-header h2 {
  color: rgb(255, 225, 107);
  font-size: clamp(1.5rem, 4vw, 2.4rem); /* Responsive font size */
  font-weight: 700;
  font-family: Georgia, 'Times New Roman', Times, serif;
  margin: 0.5rem 0;
  padding: 0;
  line-height: 1.2;
}

.form-header p {
  color: #fff6e1;
  font-size: clamp(1rem, 2vw, 1.2rem);
  margin: 1rem 0;
}

/* Form type toggle */
.form-type-toggle {
  display: flex;
  justify-content: center;
  margin: 20px auto;
  width: 100%;
  max-width: 400px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  padding: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.toggle-btn {
  flex: 1;
  padding: 12px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  transition: all 0.3s ease;
  background: transparent;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
}

.toggle-btn.active {
  background-color: #E4A11B;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.toggle-btn:hover:not(.active) {
  color: white;
  background-color: rgba(228, 161, 27, 0.3);
}

/* Form content and sections */
.form-content {
  width: 100%;
}

.form-section {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section-title {
  color: #2c3e50;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-size: clamp(1.1rem, 3vw, 1.5rem);
}

/* Form controls */
.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #34495e;
  font-weight: bold;
}

.form-control {
  width: 100%;
  padding: 8px;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.form-control:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 5px rgba(52, 152, 219, 0.3);
}

/* Radio and checkbox groups */
.radio-group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.radio-label {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.checkbox-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.checkbox-label {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 5px;
}

.checkbox-label input {
  margin-top: 3px;
}

/* Submit button */
.submit-btn {
  background-color: #E4A11B;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-top: 20px;
  transition: background-color 0.3s;
  margin-bottom:25px
}

.submit-btn:hover {
  background-color: #2980b9;
}

.submit-btn:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

/* Success and error messages */
.success-message {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #2ecc71;
  color: white;
  padding: 15px;
  border-radius: 4px;
  margin-top: 20px;
}

.error-message {
  background-color: #e74c3c;
  color: white;
  padding: 15px;
  border-radius: 4px;
  margin-top: 20px;
}

.checkmark {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2ecc71;
  font-weight: bold;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .form-wrapper {
    padding: 0 15px;
  }
  
  .radio-group {
    flex-direction: column;
    gap: 10px;
  }
  
  .form-type-toggle {
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  .form-type-toggle {
    flex-direction: column;
    border-radius: 15px;
    height: auto;
    max-width: 250px;
  }
  
  .toggle-btn {
    border-radius: 15px;
    margin: 5px 0;
    padding: 10px;
  }
  
  .form-section {
    padding: 15px;
  }
  
  .bg-img-container {
    height: 40vh;
  }
}

/* For extra-small screens */
@media (max-width: 320px) {
  .form-header h1 {
    font-size: 1.8rem;
  }
  
  .form-header h2 {
    font-size: 1.3rem;
  }
  
  .form-type-toggle {
    max-width: 200px;
  }
}

.checkbox-group {
  display: flex;
  align-items: center; /* Ensures vertical alignment */
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 8px; /* Adjust spacing between checkbox and text */
}

.checkbox-label input {
  margin: 0; /* Removes any default spacing */
  width: 16px;
  height: 16px; /* Ensures consistent checkbox size */
}
